import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateLayout from './components/HOC/PrivateLayout';
import CurriculumPage from './pages/Curriculum/Curriculum';
import Curriculum100 from './pages/Curriculum/Curriculum100.js';
import CalenderPage from './pages/Calender/Calender';
import UpskillingPage from './pages/Upskilling/Upskilling';
import NetworkPage from './pages/Network/Network';
import CareerPage from './pages/Career/Career';
import GGIValuesPage from './pages/GGIValues/GGIValues';
import FAQsPage from './pages/FAQ/FAQ';
import LoginPage from './pages/Login/Login';
import NotFound from './components/NotFound/NotFound';
import { UserContextProvider } from './Context/user-context';
import './styles/globals.css';
import 'antd/dist/antd.less';
import AdditionalLR from './pages/AdditionalLR/AdditionalLR';
import Form from './pages/forms/Form';
import Loyalty from './pages/Loyalty/Loyalty';
import UserContext from './Context/user-context';
import React, { useContext, useEffect} from 'react';

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Routes>
          <Route path='/' exact element={<LoginPage />} />
          {/* <Route
            path='/curriculum'
            element={
              <PrivateLayout>
                <CurriculumPage />
              </PrivateLayout>
            }
          /> */}
          <Route
            path='/curriculum'
            element={<CurriculumRoute />} // Use a separate component for conditional routing
          />
          <Route
            path='/masterclass-dates'
            element={
              <PrivateLayout>
                <CalenderPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/careerservice'
            element={
              <PrivateLayout>
                <CareerPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/loyalty'
            element={
              <PrivateLayout>
                <Loyalty />
              </PrivateLayout>
            }
          />
          <Route
            path='/upskilling'
            element={
              <PrivateLayout>
                <UpskillingPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/network'
            element={
              <PrivateLayout>
                <NetworkPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/additional'
            element={
              <PrivateLayout>
                <AdditionalLR />
              </PrivateLayout>
            }
          />
          <Route
            path='/ggivalues'
            element={
              <PrivateLayout>
                <GGIValuesPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/faqs'
            element={
              <PrivateLayout>
                <FAQsPage />
              </PrivateLayout>
            }
          />
          <Route
            path='/form'
            element={
              <PrivateLayout>
                <Form />
              </PrivateLayout>
            }
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

function CurriculumRoute() {
  const { data, getMyData } = useContext(UserContext);

  // Fetch user data if it's not already loaded
  useEffect(() => {
    if (!data) {
      getMyData();
    }
  }, [data, getMyData]);

  // If user data is not yet available, show a loading screen or a fallback UI
  if (!data) {
    return <div>Loading...</div>;
  }

  // Check the program name
  if (data.program && data.program[0]?.name === 'Only for our view') {
    return (
      <PrivateLayout>
        <Curriculum100 />
      </PrivateLayout>
    );
  }

  return (
    <PrivateLayout>
      <CurriculumPage />
    </PrivateLayout>
  );
}


export default App;
