import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Dropdown, Button, Row, Col, Space, Progress, Avatar } from 'antd';

import {
  BarsOutlined,
  LogoutOutlined,
  DownOutlined,
  UserOutlined,
  CalendarOutlined,
  TeamOutlined,
  ToolOutlined,
  LineChartOutlined,
  ReadOutlined,
  HeartOutlined,
  GiftOutlined
} from '@ant-design/icons';

import UserContext from '../../../Context/user-context';
import './Layout.css';
import styles from './Layout.module.less';

const { Header, Content, Sider } = Layout;

const AppLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const { error, data, img, allusers, getMyData, setProfile, getProfile, fetchAllUsers, logout } = userCtx;
  const [profile, setImage] = useState(); //for this page only
  const [file, setFile] = useState();
  useEffect(() => {
    // console.log("1st use ", img)
    setImage(img);
  }, [img]);

  useEffect(() => {
    fetchAllUsers();
  }, [data]);

  useEffect(() => {
    if (data && data.program && data.program[0]) {
      // Check if the program type is 'Only for our view' and redirect to Curriculum100
      if (data.program[0].type === 'Only for our view') {
        navigate('/curriculum100');
      }
    }
  }, [data, navigate]);
  const [sidebarItems, setSidebarItems] = useState([
    {
      title: 'Curriculum',
      link: '/curriculum',
      icon: <ReadOutlined />,
    },
    {
      title: 'Upskilling',
      link: '/upskilling',
      icon: <LineChartOutlined />,
    },
    {
      title: 'Network',
      link: '/network',
      icon: <TeamOutlined />,
    },
    // {
    //   title: 'Additional Learning Resources',
    //   link: '/additional',
    //   icon: <BarsOutlined />,
    // },
    {
      title: 'GGI Values',
      link: '/ggivalues',
      icon: <HeartOutlined />,
    },
    {
      title: 'FAQs',
      link: '/faqs',
      icon: <BarsOutlined />,
    },
  ]);
  useEffect(() => {

    setProfile(file);
    setTimeout(getProfile, 3000);
    setImage(img);
    // console.log("2nd use ", img);

  }, [file]);

  useEffect(() => {
    if (!data && !error) {
      getMyData();
    }

  }, [data]);

  useEffect(() => {
    let updatedSidebarItems = [...sidebarItems];
    console.log(data)

    if (data) {
      if (
        data.program[0].upskilling.masterclass &&
        updatedSidebarItems.find(a => a.link === '/masterclass-dates') == null
      ) {
        updatedSidebarItems.splice(1, 0, {
          title: 'Masterclass Dates',
          link: '/masterclass-dates',
          icon: <CalendarOutlined />,
        });
      }
      if (
        data.program[0].career[0] &&
        updatedSidebarItems.find(a => a.link === '/careerservice') == null
      ) {
        updatedSidebarItems.splice(4, 0, {
          title: 'Career Service',
          link: '/careerservice',
          icon: <ToolOutlined />,
        });
      }
      if (data.program[0].loyalty && updatedSidebarItems.find(a => a.link === '/loyalty') == null) {
        updatedSidebarItems.splice(5, 0, {
          title: 'Loyalty',
          link: '/loyalty',
          icon: <GiftOutlined />,
        });
      }

      if (
        data.program[0].additional &&
        updatedSidebarItems.find(a => a.link === '/additional') == null
      ) {
        updatedSidebarItems.splice(3, 0, {
          title: 'Additional Learning Resources',
          link: '/additional',
          icon: <ToolOutlined />,
        });
      }
    }
    setSidebarItems([...updatedSidebarItems]);
  }, [data]);



  const pathChangeHandler = (event, type) => {
    navigate(event.key);
  };

  const imgContainerStyle = {
    // backgroundImage: `linear-gradient(to bottom,#ffffff00,rgba(242, 90, 90, 0.432)),url(${process.env.PUBLIC_URL + '/logo.png'
    backgroundImage: `url(${process.env.PUBLIC_URL + profile})`,
  };
  //collage 
  const imgContainerStyle1 = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/collage.png'})`,
  };

  const headerMenu = (
    <Menu>

      <Menu.Item key='1' >
        <a
          href='https://forms.gle/HTtdzsefkuPvL5Hv8'
          target='_blank'
          rel='noopener noreferrer'>
          Feedback
        </a>
      </Menu.Item>
      <Menu.Item key='2' icon={<LogoutOutlined />} onClick={logout}>
        Logout
      </Menu.Item>

    </Menu>
  );

  return (
    <Layout style={{ background: 'white', minHeight: '100vh' }}>
      <Sider
        breakpoint='lg'
        collapsedWidth='0'
        className={styles.sider}
        zeroWidthTriggerStyle={{ marginTop: '15px' }}
      >
        {/* program name */}
        <h2 className={styles.imgContent}>
          {data && (
            <span className={(data.program[0].name === "Global Tech Initiative Scholar (6 months)" || data.program[0].name === "Global Tech Initiative Scholar (4 months)" || data.program[0].name === "GTI Feynman Fellow")? styles.greenText : ""}>
              {data.program[0].name}
            </span>
          )}
        </h2>


        {/* profile image */}
        <div style={imgContainerStyle} className={styles.imgContainer}>
          <label for='file' className='edit'></label>
          <input type='file' id='file' style={{ "display": "none" }} onChange={(e) => { setFile(e.target.files[0]) }} />
        </div>
        <div style={imgContainerStyle1} className={styles.imgContainer1}>
        </div>

        <Menu
          mode='inline'
          className={styles.sider__menu}
          defaultSelectedKeys={[`${location.pathname}`]}
          onClick={pathChangeHandler}
        >
          {sidebarItems.map((item, index) => (
            <Menu.Item key={item.link} icon={item.icon} title={item.title}>
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header className={styles.header}>

          <Dropdown
            overlay={headerMenu}
            placement='bottomCenter'
            trigger={['click']}
          >
            <Space wrap size={32}>
              <Avatar size={48} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            </Space>
          </Dropdown>

        </Header>
        <Layout>
          <Content className={styles.content}>{props.children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

